import { useEffect } from 'react';

export default function Index() {
  useEffect(() => {
    if (window) {
      window.open('/home', '_self');
    }
  }, []);

  return <></>;
}
